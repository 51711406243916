@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
      display: block;
      font-size: 2em;
      margin-top: 0.67em;
      margin-bottom: 0.67em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h2 {
      display: block;
      font-size: 1.5em;
      margin-top: 0.83em;
      margin-bottom: 0.83em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h3 {
      display: block;
      font-size: 1.17em;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h4 {
      display: block;
      font-size: 1em;
      margin-top: 1.33em;
      margin-bottom: 1.33em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h5 {
      display: block;
      font-size: .83em;
      margin-top: 1.67em;
      margin-bottom: 1.67em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    h6 {
      display: block;
      font-size: .67em;
      margin-top: 2.33em;
      margin-bottom: 2.33em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
  }

:root {
    --primary: #2CA73C;
    --accent: #4D7EA8;
    --dark-700: #313131;
}

html,
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    color: #454f66;
    @apply stroke-white fill-white bg-blue-50;
    touch-action: manipulation;
    font-family: "montserrat-regular", serif
}

.action {
    @apply outline-none;
    @apply hover:bg-gray-300 hover:drop-shadow-lg;
    @apply active:bg-gray-200;
}

.action > * {
    @apply outline-none;
}

.action:hover > * {
    @apply bg-gray-300;
}

.action:active > * {
    @apply bg-gray-200;
}

.hide-below-sm {
    @apply hidden sm:inline-block;
}

.side-button {
    @apply flex items-center justify-center text-white space-x-2 rounded pl-4 md:pl-0 md:py-6 p-2 md:text-lg text-sm transform lg:h-[200px] h-auto mb-2;
}

textarea::-webkit-scrollbar {
    width: 0px;
}
/* Hide arrows on input number */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.phone-popup-modal {
    z-index: 5;
    /*max-width: 300px;*/
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 13px;
    padding: 40px 30px 30px;
    position: fixed;
    top: auto;
    bottom: 20px;
    left: 20px;
    right: auto;
    box-shadow: 0 8px 12px rgb(14 13 36 / 22%);
}
.phone-popup-modal-close {
    width: 24px;
    height: 24px;
    opacity: .8;
    transition: all .2s;
    position: absolute;
    top: 10px;
    bottom: auto;
    left: auto;
    right: 10px;
}
.pop-up-fl {
    width: fit-content;
    color: #ea4067;
    background-color: #ffeaef;
    border-radius: 10px;
    margin-bottom: 16px;
    padding: 2px 16px;
    font-size: 12px;
}
.pop-up-tf {
    height: 48px;
    border: 1px solid #d8d9db;
    border-radius: 7px;
    margin-bottom: 3rem;
    font-size: 17px;
    font-weight: 600;
}
.button-submit {
    width: 190px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fd4367;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 18px 32px;
    font-weight: 600;
    text-decoration: none;
    transition: background-color .4s;
    display: flex;
}
.record-btn {
    transition: all 0.2s ease-in-out;
}
.infinite-scroll-component__outerdiv{
    width: 100%;
}
.record-btn.active {
    transform: scale(1.05);
    color: red;
    animation: beat 1.5s infinite;
}
.pagination-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #fd4367;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}
.PhoneInputInput{
    outline: none !important;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #dcb0b7;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
.no-caret {
    caret-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.bg-select-role{
    color: #1D1E22;
    background-image: linear-gradient(90deg,
    #ffffff 0%,
    #ffffff 50%,
    #fd4367 50%,
    #fd4367 100%);
    background-size: 300%;
    transition: background-position .2s cubic-bezier(.47, .1, 1, .63),
    color .2s linear;
    transition-delay: 0.0s, 0.15s;
    /*background-size: 300%;*/
    /*transition: background-position .4s cubic-bezier(.17,.67,1,.98), color .2s linear;*/
    /*transition-delay: 0.2s, 0.5s;*/
}

.bg-select-role:hover i{
    background: #ffffff;
}
.bg-select-role i svg path{
    fill: #fd4367;
}
.bg-select-role:hover svg path{
    stroke: #ffffff;
}
.bg-select-role:hover{
    color: #ffffff;
    cursor: pointer;
    background-position: -50% 300%;
}

@keyframes beat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
.font-poppins{
    font-family: "poppins-regular", serif;
}
.font-montserrat{
    font-family: "montserrat-regular", serif;
}
@font-face {
    font-family: 'poppins-regular';
    src: url('../public/fonts/Poppins-Regular.ttf') format('truetype'),
    url('../public/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

stripe-pricing-table {
    width: 640px;
}
@font-face {
    font-family: 'montserrat-regular';
    src: url('../public/fonts/Montserrat-Regular.ttf') format('truetype'),
    url('../public/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@media (min-width: 769px) {
    .vertical-text {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
    }
    .no-vertical-text {
        writing-mode: rl;
    }
}
